import Vue from 'vue'
import Router from 'vue-router'
import constantRouter from './modules/constantRouter.js'

// hack router push callback
const originalPush = Router.prototype.push
Router.prototype.push = function push(location, onResolve, onReject) {
	if (onResolve || onReject)
		return originalPush.call(this, location, onResolve, onReject)
	return originalPush.call(this, location).catch((err) => err)
}
// hack router replace callback
const originalReplace = Router.prototype.push
Router.prototype.replace = function replace(location, onResolve, onReject) {
	if (onResolve || onReject)
		return originalReplace.call(this, location, onResolve, onReject)
	return originalReplace.call(this, location).catch((err) => err)
}
Vue.use(Router)

const commonRoutes = [{
		path: '/dashboard',
		component: () => import('@/layout/index.vue'),
		children:[
			{
				path: 'index',
				component: () => import('@/views/dashboard/index.vue'),
				meta: {
					title: '首页',
					showTitle: false,//false隐藏左侧箭头一级头部标题
					showMessage:true,//true 显示消息
					// hidden:true,//true 隐藏底部导航栏 为false或不设置变量显示导航栏
				},
			},
			{
				path: 'dashboardDetail',
				component: () => import('@/views/dashboardDetail/index.vue'),
				meta: {
					title: '子项目看板',
					showMessage:true,
					showTitle: false,
				},
			},
			{
				path: 'askforleave',
				component: () => import('@/views/applyFor/askforleave/index.vue'),
				meta: {
					title: '子项目看板',
					showMessage:true,
					showTitle: false,
				},
			},
			{
				path: 'dashboardDetail',
				component: () => import('@/views/dashboardDetail/index.vue'),
				meta: {
					title: '子项目看板',
					showMessage:true,
					showTitle: false,
				},
			}
		]
	},
	{
		path: '/home',
		component: () => import('@/layout/index.vue'),
		children:[
			{
				path: 'index',
				component: () => import('@/views/layouts/index.vue'),
				meta: {
					title: '工作台',
					showMessage:true,
					showTitle: false,
				},
			},
			{
				path: '/layoutsDetails',
				component: () => import('@/views/layoutsDetails/index.vue'),
				meta: {
					title: '事项详情',
					showTitle: false,
				},
				children: [
					{
						path: "rpm_opportunity_quote",
						name: "报价待办",
						component: () => import("@/views/layoutsDetails/opportunity/index"),
						meta: {
							title: "报价待办",
							showTitle: true,
						}
					},
					{
						path: "rpm_pre_project",
						name: "预立项待办",
						component: () => import("@/views/layoutsDetails/preproject/index"),
						meta: {
							title: "预立项待办",
							showTitle: true,
						}
					},
					{
						path: "rpm_procurement",
						name: "采购申请待办",
						component: () => import("@/views/layoutsDetails/procurement/index"),
						meta: {
							title: "采购申请待办",
							showTitle: true,
						}
					},
					{
						path: "rpm_procurement_order",
						name: "采购下单待办",
						component: () => import("@/views/layoutsDetails/procurementOrder/index"),
						meta: {
							title: "采购下单待办",
							showTitle: true,
						}
					},
					{
						path: "rpm_procurement_pay",
						name: "采购付款待办",
						component: () => import("@/views/layoutsDetails/procurementPay/index"),
						meta: {
							title: "采购付款待办",
							showTitle: true,
						}
					},
					{
						path: "rpm_budget",
						name: "预算申请待办(分类预算)",
						component: () => import("@/views/layoutsDetails/budget/index"),
						meta: {
							title: "预算申请待办(分类预算)",
							showTitle: true,
						}
					},
					{
						path: "rpm_budget_commit",
						name: "预算申请待办(总预算)",
						component: () => import("@/views/layoutsDetails/budgetCommit/index"),
						meta: {
							title: "预算申请待办(总预算)",
							showTitle: true,
						}
					},
					{
						path: "rpm_prize",
						name: "奖金申请待办",
						component: () => import("@/views/layoutsDetails/prize/index"),
						meta: {
							title: "奖金申请待办",
							showTitle: true,
						}
					},
					{
						path: "rpm_billing",
						name: "开票申请待办",
						component: () => import("@/views/layoutsDetails/billing/index"),
						meta: {
							title: "开票申请待办",
							showTitle: true,
						}
					},
					{
						path: "rpm_receivable",
						name: "收款确认待办",
						component: () => import("@/views/layoutsDetails/financia/receivable/caymentConfirmation/index"),
						meta: {
							title: "收款确认待办",
							showTitle: true,
						}
					},
					{
						path: "rpm_contract_sale",
						name: "销售合同申请",
						component: () => import("@/views/layoutsDetails/contract/procurementcontract/index"),
						meta: {
							title: "销售合同申请",
							showTitle: true,
						}
					},
					{
						path: "rpm_contract_procure",
						name: "采购合同申请待办",
						component: () => import("@/views/layoutsDetails/contract/salescontract/index"),
						meta: {
							title: "采购合同申请待办",
							showTitle: true,
						}
					},
					{
						path: "rpm_project",
						name: "项目立项待办",
						component: () => import("@/views/layoutsDetails/preproject/projectList/index"),
						meta: {
							title: "项目立项待办",
							showTitle: true,
						}
					},
					{
						path: "rpm_project_accept",
						name: "项目验收待办",
						component: () => import("@/views/layoutsDetails/preproject/acceptancestorage/index"),
						meta: {
							title: "项目验收待办",
							showTitle: true,
						}
					},
					{
						path: "rpm_project_conclude",
						name: "项目结题待办",
						component: () => import("@/views/layoutsDetails/preproject/projectconclusion/index"),
						meta: {
							title: "项目结题待办",
							showTitle: true,
						}
					},
					{
						path: "rpm_project_close",
						name: "项目撤题待办",
						component: () => import("@/views/layoutsDetails/preproject/projectwithdrawal/index"),
						meta: {
							title: "项目撤题待办",
							showTitle: true,
						}
					},
					{
						path: "rpm_repertory_store",
						name: "验收入库申请待办",
						component: () => import("@/views/layoutsDetails/Inventory/acceptancestorage/index"),
						meta: {
							title: "验收入库申请待办",
							showTitle: true,
						}
					},
					// /Users/zhangbengbeng/Desktop/蓝俊代码/jlx_rpm_app/src/views/layoutsDetails/Inventory/outbound/index.vue
					{
						path: "rpm_repertory_out",
						name: "出库申请待办",
						component: () => import("@/views/layoutsDetails/Inventory/outbound/index"),
						meta: {
							title: "出库申请待办",
							showTitle: true,
						}
					},
					{
						path: "rpm_meeting_minutes",
						name: "会议纪要申请待办",
						component: () => import("@/views/layoutsDetails/meeting/meetingminutes/index"),
						meta: {
							title: "会议纪要申请待办",
							showTitle: true,
						}
					},
					{
						path: "rpm_meeting_minutes",
						name: "资产转移申请待办",
						component: () => import("@/views/layoutsDetails/asset/ransferrecords/index"),
						meta: {
							title: "资产转移申请待办",
							showTitle: true,
						}
					},
					{
						path: "rpm_asset_borrow",
						name: "资产借用申请待办",
						component: () => import("@/views/layoutsDetails/asset/borrowingrecords/index"),
						meta: {
							title: "资产借用申请待办",
							showTitle: true,
						}
					},
					{
						path: "rpm_asset_return",
						name: "资产归还申请待办",
						component: () => import("@/views/layoutsDetails/asset/assetReturn/index"),
						meta: {
							title: "资产归还申请待办",
							showTitle: true,
						}
					},
					{
						path: "rpm_asset_opt",
						name: "资产报废申请待办",
						component: () => import("@/views/layoutsDetails/asset/scraprecords/index"),
						meta: {
							title: "资产报废申请待办",
							showTitle: true,
						}
					},
					{
						path: "rpm_asset_repair",
						name: "资产维修申请待办",
						component: () => import("@/views/layoutsDetails/asset/maintenancerecords/index"),
						meta: {
							title: "资产维修申请待办",
							showTitle: true,
						}
					},
					{
						path: "rpm_quality_doc",
						name: "质量文件新增申请待办",
						component: () => import("@/views/layoutsDetails/quality/file/index"),
						meta: {
							title: "质量文件新增申请待办",
							showTitle: true,
						}
					},
					{
						path: "rpm_quality_preparation",
						name: "特殊制剂内部委托制备申请待办",
						component: () => import("@/views/layoutsDetails/quality/qualitypreparation/index"),
						meta: {
							title: "特殊制剂内部委托制备申请待办",
							showTitle: true,
						}
					},
					{
						path: "rpm_quality_inside",
						name: "内部送样申请待办",
						component: () => import("@/views/layoutsDetails/quality/qualityinside/index"),
						meta: {
							title: "内部送样申请待办",
							showTitle: true,
						}
					},
					{
						path: "rpm_quality_out",
						name: "委外送样申请待办",
						component: () => import("@/views/layoutsDetails/quality/qualityout/index"),
						meta: {
							title: "委外送样申请待办",
							showTitle: true,
						}
					},
					{
						path: "rpm_quality_key",
						name: "钥匙借用申请待办",
						component: () => import("@/views/layoutsDetails/quality/keyborrow/index"),
						meta: {
							title: "钥匙借用申请待办",
							showTitle: true,
						}
					},
					{
						path: "rpm_quality_user",
						name: "用户管理申请待办",
						component: () => import("@/views/layoutsDetails/quality/usermanagement/index"),
						meta: {
							title: "用户管理申请待办",
							showTitle: true,
						}
					},
					{
						path: "rpm_doc",
						name: "文件新增申请待办",
						component: () => import("@/views/layoutsDetails/documentFile/file/index"),
						meta: {
							title: "文件新增申请待办",
							showTitle: true,
						}
					},
					{
						path: "rpm_hr_vacation",
						name: "请假申请代办",
						component: () => import("@/views/layoutsDetails/hr/vacation.vue"),
						meta: {
							title: "请假申请代办",
							showTitle: true
						}
					},
					{
						path: "rpm_hr_travel",
						name: "出差申请代办",
						component: () => import("@/views/layoutsDetails/hr/travel.vue"),
						meta: {
							title: "出差申请代办",
							showTitle: true
						}
					},
					{
						path: "rpm_hr_overtime",
						name: "加班申请代办",
						component: () => import("@/views/layoutsDetails/hr/overtime.vue"),
						meta: {
							title: "加班申请代办",
							showTitle: true
						}
					},
					{
						path: "rpm_hr_input",
						name: "录用申请代办",
						component: () => import("@/views/layoutsDetails/hr/input.vue"),
						meta: {
							title: "录用申请代办",
							showTitle: true
						}
					},
					{
						path: "rpm_hr_retire",
						name: "离职申请代办",
						component: () => import("@/views/layoutsDetails/hr/resignation.vue"),
						meta: {
							title: "离职申请代办",
							showTitle: true
						}
					},
					{
						path: "rpm_hr_stamps",
						name: "用章申请代办",
						component: () => import("@/views/layoutsDetails/hr/stamps.vue"),
						meta: {
							title: "用章申请代办",
							showTitle: true
						}
					},
				]
			},
		]
		
	},
	{
		path: '/function',
		component: () => import('@/layout/index.vue'),
		children:[
			{
				path: 'index',
				component: () => import('@/views/function/index.vue'),
				meta: {
					title: '功能',
					showMessage:true,
					showTitle: false,
				},
			},
			{
				path: 'outboundList',
				name:"outboundList",
				component: () => import('@/views/function/outbound/list.vue'),
				meta: {
					title: '出库管理',
					showTitle: true,
					childName:'outboundDetail',
					processKey:"rpm_outbound_OutboundForm"
				},
			},
			{
				path: 'outboundDetail',
				name:"outboundDetail",
				component: () => import('@/views/function/outbound/detail.vue'),
				meta: {
					title: '出库管理',
					showTitle: true,
					hidden:true,
				},
			},
			{
				path: 'workingHoursList',
				name:"workingHoursList",
				component: () => import('@/views/function/workinghours/list.vue'),
				meta: {
					title: '工时管理',
					showTitle: true,
					childName:'workingHoursDetail',
					processKey:"rpm_project_workhour"
				},
			},
			{
				path: 'workingHoursDetail',
				name:"workingHoursDetail",
				component: () => import('@/views/function/workinghours/detail.vue'),
				meta: {
					title: '工时管理',
					showTitle: true,
					hidden:true,
				},
			},
			{
				path: 'meetingAdminList',
				name:"meetingAdminList",
				component: () => import('@/views/function/meeting/meetingadmin/list.vue'),
				meta: {
					title: '会议管理',
					showTitle: true,
					childName:'meetingAdminDetail'
				},
			},
			{
				path: 'meetingAdminDetail',
				name:"meetingAdminDetail",
				component: () => import('@/views/function/meeting/meetingadmin/detail.vue'),
				meta: {
					title: '会议管理',
					showTitle: true,
					hidden:true,
				},
			},
			{
				path: 'taskmanagementList',
				name:"taskmanagementList",
				component: () => import('@/views/function/taskmanagement/list.vue'),
				meta: {
					title: '任务管理',
					showTitle: true,
					childName: 'taskmanagementDetail',
				},
			},
			{
				path: 'taskmanagementDetail',
				name:"taskmanagementDetail",
				component: () => import('@/views/function/taskmanagement/detail.vue'),
				meta: {
					title: '任务管理',
					showTitle: true,
					hidden:true,
				},
			},
			{
				path: "leaveApplicationList",
				name: "leaveApplicationList",
				component: () => import('@/views/function/leaveApplication/list.vue'),
				meta: {
					title: "请假申请",
					showTitle: true,
					childName: "leaveApplicationDetail",
					processKey:"rpm_personnel_leave"
				}
			},
			{
				path: "leaveApplicationDetail",
				name: "leaveApplicationDetail",
				component: () => import('@/views/function/leaveApplication/detail.vue'),
				meta: {
					title: "请假申请",
					showTitle: true,
					hidden: true
				}
			},
			{
				path: "workOverTimeList",
				name: "workOverTimeList",
				component: () => import('@/views/function/workovertime/list.vue'),
				meta: {
					title: "加班申请",
					showTitle: true,
					childName: "workOverTimeDetail",
					processKey:"rpm_personnel_overtime"
				}
			},
			{
				path: "workOverTimeDetail",
				name: "workOverTimeDetail",
				component: () => import('@/views/function/workovertime/detail.vue'),
				meta: {
					title: "加班申请",
					showTitle: true,
					hidden: true
				}
			},
			{
				path: "employmentList",
				name: "employmentList",
				component: () => import('@/views/function/employment/list.vue'),
				meta: {
					title: "录用申请",
					showTitle: true,
					childName: "employmentDetail",
					processKey:"rpm_personnel_employment"
				}
			},
			{
				path: "employmentDetail",
				name: "employmentDetail",
				component: () => import('@/views/function/employment/detail.vue'),
				meta: {
					title: "录用申请",
					showTitle: true,
					hidden: true
				}
			},
			{
				path: "resignationList",
				name: "resignationList",
				component: () => import('@/views/function/resignation/list.vue'),
				meta: {
					title: "离职申请",
					showTitle: true,
					childName: "resignationDetail",
					processKey:"rpm_personnel_resignation"
				}
			},
			{
				path: "resignationDetail",
				name: "resignationDetail",
				component: () => import('@/views/function/resignation/detail.vue'),
				meta: {
					title: "离职申请",
					showTitle: true,
					hidden: true
				}
			},
			{
				path: "stampsList",
				name: "stampsList",
				component: () => import('@/views/function/stamps/list.vue'),
				meta: {
					title: "用章申请",
					showTitle: true,
					childName: "stampsDetail",
					processKey:"rpm_personnel_stamps"
				}
			},
			{
				path: "stampsDetail",
				name: "stampsDetail",
				component: () => import('@/views/function/stamps/detail.vue'),
				meta: {
					title: "用章申请",
					showTitle: true,
					hidden: true
				}
			},
			{
				path: "meetingMinutesList",
				name: "meetingMinutesList",
				component: () => import('@/views/function/meetingminutes/list.vue'),
				meta: {
					title: "会议纪要",
					showTitle: true,
					childName: "meetingMinutesDetail",
					processKey:"rpm_meeting_meetingminutes"
				}
			},
			{
				path: "meetingMinutesDetail",
				name: "meetingMinutesDetail",
				component: () => import('@/views/function/meetingminutes/detail.vue'),
				meta: {
					title: "会议纪要",
					showTitle: true,
					hidden: true
				}
			},
		]
	},
	{
		path: '/message',
		component: () => import('@/layout/index.vue'),
		children:[
			{
				path: 'index',
				name:"messageIndex",
				component: () => import('@/views/message/index.vue'),
				meta: {
					title: '消息中心',
					showTitle: true,
				},
			},{
				path: 'detail',
				name:"messageDetail",
				component: () => import('@/views/message/detail.vue'),
				meta: {
					title: '消息中心',
					showTitle: true,
				},
			}
		]
	},
	{
		path: '/mine',
		component: () => import('@/layout/index.vue'),
		children:[
			{
				path: 'index',
				component: () => import('@/views/mine'),
				meta: {
					title: '我的',
					showMessage:true,
					showTitle: false,
				},
			}
		]
	},
	{
		path: '/login',
		component: () => import('@/views/login'),
		meta: {
			title: ' ',
		},
	},
	{
		path: '/task',
		component: () => import('@/views/task'),
		meta: {
			title: '任务管理',
			showTitle: false,
		},
	},
	{
		path: '/taskDetail',
		component: () => import('@/views/task/taskDetail'),
		meta: {
			title: '任务管理',
		},
	},
	{
		path: '/questionEnter',
		component: () => import('@/views/task/questionEnter'),
		meta: {
			title: '任务管理',
		},
	},
	{
		path: '/sample',
		component: () => import('@/views/sample'),
		meta: {
			title: '样品管理',
		},
	},
	{
		path: '/sampleUse',
		component: () => import('@/views/sample/sampleUse'),
		meta: {
			title: '样品管理',
		},
	},
	{
		path: '/returnSample',
		component: () => import('@/views/sample/returnSample'),
		meta: {
			title: '样品管理',
		},
	},
	{
		path: '/remind',
		component: () => import('@/views/mine/remind'),
		meta: {
			title: '我的',
		},
	},
	{
		path: '/mineUser',
		component: () => import('@/views/mine/mineUser'),
		meta: {
			title: '我的',
		},
	},
	{
		path: '/device',
		component: () => import('@/views/device'),
		meta: {
			title: '设备点检',
		},
	},
]

const routes = [...commonRoutes, ...constantRouter]
const createRouter = () =>
	new Router({
		// mode: 'history', // 如果你是 history模式 需要配置vue.config.js publicPath
		// base: process.env.BASE_URL,
		scrollBehavior: () => ({
			y: 0,
		}),
		routes,
	})

const router = createRouter()

// Detail see: https://github.com/vuejs/vue-router/issues/1234#issuecomment-357941465
export function resetRouter() {
	const newRouter = createRouter()
	router.matcher = newRouter.matcher // reset router
}

router.beforeEach((to, from, next) => {
	var href = window.location.href
	let origin = window.location.origin
	let code = to.query.code
	// hash模式下，跳转的url并不会按我们想的那样，把#拼在index.html后边，而是会加在url的参数的最后，所以这里要做一下判断，如果是从微信跳转来的，就直接带着我需要的参数code跳到登录页
	if (href.includes('com/jili_v2/m/?code')) {
		//url包括 com/?code 证明为从微信登录授权跳转回来的
		window.location.replace(
			origin +
			'/jili_v2/m/#/login?' +
			location.href.split('?')[1].split('#/login')[0]
		) //拼接跳转

		// if (href.includes("9020/?code")) {
		//   //url包括 com/?code 证明为从微信登录授权跳转回来的
		//   window.location.replace(
		//     origin + "/#/login?" + location.href.split("?")[1].split("#/login")[0]
		//   ); //拼接跳转
	} else {
		if (to.meta.requireAuth) {
			// 这个很重要，我尝试过直接判断路径是否是首页的方式，结果苹果手机没有问题，安卓手机全部都是死循环一直跳到授权登录页。使用to.meta.requireAuth 这样就正常了。
			// if (code && !token && !usertype) {
			//   // 有code且没有token和角色（已授权未登录）
			//   localStorage.setItem("code", code); // 保存code,跳到登录页
			//   next("/login");
			// } else if (!code && token && usertype) {
			//   //已登录，有token
			//   next();
			// } else if (!code && !token && !usertype) {
			//   //未授权未登录，没有token和角色
			//   //获取授权
			//   window.location.href =
			//     "https://open.weixin.qq.com/connect/oauth2/authorize?appid=" +
			//     appid +
			//     "&redirect_uri=" +
			//     redirect_uri +
			//     "&response_type=code&scope=snsapi_userinfo&state=STATE#wechat_redirect";
			// }
		} else {
			// 当不是首页时，直接进入路由
			next()
		}
	}
	// next();
})
export default router