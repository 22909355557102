import Vue from "vue";
import { login } from "@/api/login";
import {
  ACCESS_TOKEN,
  USER_NAME,
  USER_INFO,
  USER_AUTH,
  SYS_BUTTON_AUTH,
  UI_CACHE_DB_DICT_DATA,
  TENANT_ID,
  CACHE_INCLUDED_ROUTES,
} from "@/store/mutation-types";
import { JSEncrypt } from "jsencrypt";
import { queryPermissionsByUser } from "@/api/api";
import { getAction } from "@/api/manage";

const user = {
  state: {
    token: "",
    username: "",
    realname: "",
    tenantid: "",

    avatar: "",
    permissionList: [],
    processList: [],
    info: {},
    // 系统安全模式
    sysSafeMode: null,
  },

  mutations: {
    SET_TOKEN: (state, token) => {
      state.token = token;
    },
    SET_NAME: (state, { username, realname, welcome }) => {
      state.username = username;
      state.realname = realname;
      state.welcome = welcome;
    },
    SET_AVATAR: (state, avatar) => {
      state.avatar = avatar;
    },
    SET_PERMISSIONLIST: (state, permissionList) => {
      state.permissionList = permissionList;
    },
    SET_PROCESSLIST: (state, processList) => {
      state.processList = processList;
    },
    SET_INFO: (state, info) => {
      state.info = info;
    },
    SET_TENANT: (state, id) => {
      state.tenantid = id;
    },
    SET_SYS_SAFE_MODE: (state, sysSafeMode) => {
      if (typeof sysSafeMode === "boolean") {
        state.sysSafeMode = sysSafeMode;
      } else {
        state.sysSafeMode = false;
      }
    },
  },

  actions: {
    SetToken({ commit }, userInfo) {
      console.log(userInfo);

      let encryptor = new JSEncrypt();
      let str =
        "MIGfMA0GCSqGSIb3DQEBAQUAA4GNADCBiQKBgQCq187lNITteMmmMCx0oxLU9m1Jhhxy6ftLfr1WcdNE9rQJBgaYoPhO5thLOD6OipS/SjAqA67aerkm0q1QRkjtpQn9gL478uCkPzjSML/td6dr7UrEGkpQQkUA8WPW2GuWQ+WzWV1wPu5RB3mE+h7IBZJMK4O/HXCtE3hHbBfFmQIDAQAB";
      encryptor.setPublicKey(str);
      // "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJleHAiOjE2OTUxNzYxNTEsInVzZXJuYW1lIjoiYWRtaW4ifQ.vrOW-mDkMdEoVDy-BQGa4C06h5dS-MtS0DzKH1iBN-M";
      let token = userInfo.token;
      let user = userInfo.userInfo;
      commit("SET_TOKEN", token);
      commit("SET_INFO", user);
      Vue.ls.set(ACCESS_TOKEN, token, 7 * 24 * 60 * 60 * 1000);
      commit("SET_TOKEN", token);
      Vue.ls.set(USER_INFO, user, 7 * 24 * 60 * 60 * 1000);
      commit("SET_INFO", user);
      return true;
    },
    // 登录
    Login({ commit }, userInfo) {
      debugger;
      return new Promise((resolve, reject) => {
        login(userInfo)
          .then((response) => {
            if (response.code == "200") {
              const result = response.result;
              const userInfo = result.userInfo;
              Vue.ls.set(ACCESS_TOKEN, result.token, 7 * 24 * 60 * 60 * 1000);
              Vue.ls.set(USER_NAME, userInfo.username, 7 * 24 * 60 * 60 * 1000);
              Vue.ls.set(USER_INFO, userInfo, 7 * 24 * 60 * 60 * 1000);
              Vue.ls.set(
                UI_CACHE_DB_DICT_DATA,
                result.sysAllDictItems,
                7 * 24 * 60 * 60 * 1000
              );
              commit("SET_TOKEN", result.token);
              commit("SET_INFO", userInfo);
              commit("SET_NAME", {
                username: userInfo.username,
                realname: userInfo.realname,
                welcome: welcome(),
              });
              commit("SET_AVATAR", userInfo.avatar);
              resolve(response);
            } else {
              reject(response);
            }
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    // 获取用户信息
    GetPermissionList({ commit }) {
      return new Promise((resolve, reject) => {
        queryPermissionsByUser()
          .then((response) => {
            const menuData = response.result.menu;
            const authData = response.result.auth;
            const processData = response.result.process;
            const allAuthData = response.result.allAuth;
            //Vue.ls.set(USER_AUTH,authData);
            sessionStorage.setItem(USER_AUTH, JSON.stringify(authData));
            sessionStorage.setItem(
              SYS_BUTTON_AUTH,
              JSON.stringify(allAuthData)
            );
            // menuData.push({
            //     "redirect":null,
            //     "path":"/expenses",
            //     "component":"layouts/RouteView",
            //     "route":"1",
            //     "children": [
            //         {
            //             "name":"expenses-todo",
            //             "path":"/expenses/todo",
            //             "component":"expenses/ExpenseList",
            //             "route":"1",
            //             "meta":{
            //                 "keepAlive":false,
            //                 "internalOrExternal":false,
            //                 "componentName":"ExpenseList",
            //                 "title":"费用预算"
            //             },
            //         },

            //     ],

            //     "meta":{
            //         "keepAlive":false,
            //         "internalOrExternal":false,
            //         "icon":"user",
            //         "componentName":"RouteView",
            //         "title":"费用预算"
            //     },
            //     "name":"expenses",
            //     "id":"1583029054024454145"
            // })

            if (menuData && menuData.length > 0) {
              // //update--begin--autor:qinfeng-----date:20200109------for：JEECG-63 一级菜单的子菜单全部是隐藏路由，则一级菜单不显示------
              // menuData.forEach((item, index) => {
              //   if (item["children"]) {
              //     let hasChildrenMenu = item["children"].filter((i) => {
              //       return !i.hidden || i.hidden == false
              //     })
              //     if (hasChildrenMenu == null || hasChildrenMenu.length == 0) {
              //       item["hidden"] = true
              //     }
              //   }
              // })
              // //console.log(" menu show json ", menuData)
              // //update--end--autor:qinfeng-----date:20200109------for：JEECG-63 一级菜单的子菜单全部是隐藏路由，则一级菜单不显示------
              commit("SET_PERMISSIONLIST", menuData);
              // 流程表单路由
              commit("SET_PROCESSLIST", processData);
              // 设置系统安全模式
              commit("SET_SYS_SAFE_MODE", response.result.sysSafeMode);
            } else {
              reject(
                "getPermissionList: permissions must be a non-null array !"
              );
            }
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
  },
};

export default user;
