//无权限路由
const constantRouter = [
  {
    path: "/",
    component: () => import("@/views/layouts/index.vue"),
    redirect: "/login",
    meta: {
      title: "加载中",
      keepAlive: false,
    },
  },
];

export default constantRouter;
