const api = {
  Login: "/sys/login",
  Logout: "/sys/logout",
  ForgePassword: "/auth/forge-password",
  Register: "/auth/register",
  SendSms: "/account/sms",
  // get my info
  UserInfo: "/user/info",
};
export default api;
