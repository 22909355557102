import "./config";
import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import Vant from "vant";
import "vant/lib/index.css";
import Storage from "vue-ls";
Vue.use(Vant);
import "amfe-flexible";
import vxeTable from 'vxe-table';
import 'vxe-table/lib/style.css'

import vueMobileTable from 'vue-mobile-table'
Vue.use(vueMobileTable)
import config from "@/defaultSettings";
import * as filters from "./filters";
import Vconsole from "vconsole";
Object.keys(filters).forEach((key) => {
  Vue.filter(key, filters[key]);
});
Vue.use(vxeTable);

console.log(process.env);
if (process.env.VUE_APP_VC === "vConsole") {
  const vConsole = new Vconsole();
}
Vue.config.productionTip = false;
Vue.use(Storage, config.storageOptions);
new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
