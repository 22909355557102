import Vue from "vue";
import { USER_INFO } from "@/store/mutation-types";
const getters = {
  username: (state) => state.user.username,
  userInfo: (state) => {
    state.user.info = Vue.ls.get(USER_INFO);

    return state.user.info;
  },
};
export default getters;
